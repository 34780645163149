<template>
    <div class="phone-verification-form">
        <phone-verification-skeleton v-if="loadingAccount" />
        <template v-else>
            <h1 class="phone-verification-form__title">
                {{ hasIncompleteInformation ? $t('Complete your information') : $t('Verify your phone') }}
            </h1>
            <template v-if="hasIncompleteInformation">
                <div class="phone-verification-form__form">
                    <p class="phone-verification-form__info-text mb-0">
                        {{ $t('We detected that you still need to complete some information required for the proper functioning of your account, in order to register this information you can click on the following button') }}
                    </p>
                    <ui-button
                        class="w-100 mb-0"
                        type="submit"
                        variant="accent"
                        @click="$refs.pendingAccountInformationModal.show()"
                    >
                        {{ $t('Update information') }}
                    </ui-button>
                </div>
            </template>
            <template v-else>
                <p class="phone-verification-form__info-text" v-sanitize="getMessageByType(notificationType, getPhone())"></p>
                <form class="phone-verification-form__form" @submit.prevent="handleSubmit">
                    <b-alert v-if="tooManyRequests" show variant="danger" class="text-center mb-0">
                        {{ $t('You have exceeded your request limit, to continue with your registration you can start a support chat') }}.
                    </b-alert>
                    <div class="text-left">
                        <ui-input
                            id="phone-code-verification"
                            name="phoneCode"
                            type="number"
                            placeholder="0000"
                            v-model="formData.verificationCode"
                            :label="$t('Code')"
                            :field="v$.formData.verificationCode"
                        >
                        </ui-input>
                    </div>
                    <ui-button
                        :loading="validatingCode"
                        class="w-100 mb-0"
                        type="submit"
                    >
                        {{ $t('Validate code') }}
                    </ui-button>
                    <ui-button
                        :disabled="timeRemaining > 0 || validatingCode || tooManyRequests || sendingCode"
                        :loading="sendingCode && temporalNotificationType === notificationType"
                        class="w-100 mb-0"
                        variant="secondary"
                        @click="sendCodeRecaptcha(notificationType)"
                    >
                        {{ $t('Resend code') }}
                    </ui-button>
                </form>
                <vue-recaptcha
                    class="w-100"
                    ref="codeRecaptcha"
                    size="invisible"
                    loadRecaptchaScript
                    :sitekey="CONST.CAPTCHACODE"
                    @verify="validateCode"
                />
                <p v-if="timeRemaining > 0" class="phone-verification-form__wait-message">
                    {{ $t('It is necessary to wait to resend the code {seconds} seconds', { seconds: timeRemaining }) }}
                </p>
                <p class="phone-verification-form__update-phone" :class="{ 'text-muted': timeRemaining > 0 }">
                    {{ $t('Is your number wrong?') }}
                    <button
                        class="phone-verification-form__link btn btn-link p-0"
                        :disabled="timeRemaining > 0"
                        @click="$refs.phoneNumberModal.show()"
                    >
                        {{ $t('Change It') }}
                    </button>
                </p>
                <div class="phone-verification-form__other-methods">
                    <ui-button
                        v-if="notificationType !== notificationTypes.WHATSAPP"
                        class="phone-verification-form__other-method-button"
                        variant="secondary"
                        :disabled="timeRemaining > 0 || validatingCode || tooManyRequests || sendingCode"
                        :loading="sendingCode && temporalNotificationType === notificationTypes.WHATSAPP"
                        @click="sendCodeRecaptcha(notificationTypes.WHATSAPP)"
                    >
                        <span v-if="!(sendingCode && temporalNotificationType === notificationTypes.WHATSAPP)" class="phone-verification-form__other-method-icon fab fa-whatsapp"></span>
                        <span class="phone-verification-form__other-method-button-text">
                            {{ $t('Get a code via WhatsApp') }}
                        </span>
                        <span class="phone-verification-form__other-method-arrow fas fa-arrow-right"></span>
                    </ui-button>
                    <ui-button
                        v-if="notificationType !== notificationTypes.OMNI"
                        class="phone-verification-form__other-method-button"
                        variant="secondary"
                        :disabled="timeRemaining > 0 || validatingCode || tooManyRequests || sendingCode"
                        :loading="sendingCode && temporalNotificationType === notificationTypes.OMNI"
                        @click="sendCodeRecaptcha(notificationTypes.OMNI)"
                    >
                        <span v-if="!(sendingCode && temporalNotificationType === notificationTypes.OMNI)" class="phone-verification-form__other-method-icon fas fa-sms"></span>
                        <span class="phone-verification-form__other-method-button-text">
                            {{ $t('Get a code via SMS') }}
                        </span>
                        <span class="phone-verification-form__other-method-arrow fas fa-arrow-right"></span>
                    </ui-button>
                </div>
                <vue-recaptcha
                    class="w-100"
                    ref="recaptchaComponent"
                    size="invisible"
                    loadRecaptchaScript
                    :sitekey="CONST.CAPTCHACODE"
                    @verify="sendCode"
                />
                <phone-number-modal ref="phoneNumberModal" :country="user.country" :updating="!!user.phone" @phoneUpdated="phoneUpdated" />
            </template>
            <pending-account-information-modal ref="pendingAccountInformationModal" :user="user" @accountInformationUpdated="phoneUpdated" />
        </template>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, maxLength, minLength, required } from '@vuelidate/validators';
import parsePhoneNumber from 'libphonenumber-js'
import VueRecaptcha from 'vue-recaptcha';

import PendingAccountInformationModal from '@/components/accounts/PendingAccountInformationModal.vue';
import PhoneNumberModal from '@/components/phone-verification/PhoneNumberModal.vue';
import PhoneVerificationSkeleton from '@/components/ui/skeletons/PhoneVerificationSkeleton.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import UiInput from '@/components/ui/inputs/Input.vue';

import recaptchaUtils from '@/utils/recaptcha.utils';

import { OMNI, WHATSAPP } from '@/constants/notification-types';

export default {
    name: 'PhoneVerification',
    components: {
        PendingAccountInformationModal,
        PhoneNumberModal,
        PhoneVerificationSkeleton,
        UiButton,
        UiInput,
        VueRecaptcha,
    },
    setup() {
        return {
            v$: useVuelidate({ $scope: false }),
        };
    },
    data() {
        return {
            waitTime: 30,
            loading: false,
            loadingAccount: true,
            tooManyRequests: false,
            notificationType: OMNI,
            notificationTypes: {
                OMNI,
                WHATSAPP,
            },
            temporalNotificationType: null,
            timeInterval: null,
            timeRemaining: 0,
            redirectUrl: null,
            sendingCode: false,
            siteId: null,
            user: null,
            validatingCode: false,
            formData: {
                verificationCode: '',
            },
        };
    },
    computed: {
        hasIncompleteInformation() {
            return !this.user?.phone || !this.user?.country;
        },
    },
    validations() {
        return {
            formData: {
                verificationCode: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('code') }), required),
                    minLength: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params ), minLength(4)),
                    maxLength: helpers.withMessage(({ $params }) => this.$t('This field must have a maximum of {max} characters', $params ), maxLength(4)),
                    $autoDirty: true,
                },
            }
        };
    },
    async mounted() {
        if (this.$route.query.site_id) {
            this.siteId = this.$route.query.site_id;
        }

        if (this.$route.query.redirect_url) {
            this.redirectUrl = this.$route.query.redirect_url;
        }

        await this.getAccountDetails();

        this.$nextTick(() => {
            this.setTimeRemaining();
        })
    },
    methods: {
        redirectToLogin() {
            const query_params = {};

            if (this.user.email) {
                query_params.email = this.user.email;
            }

            if (this.siteId) {
                query_params.site_id = this.siteId;
            }

            if (this.redirectUrl) {
                query_params.redirect_url = this.redirectUrl;
            }

            this.$router.push({ path: `/login`, query: query_params});
        },
        setTimeRemaining() {
            this.timeRemaining = this.waitTime;
            this.timeInterval = setInterval(() => {
                if (this.timeRemaining > 0) {
                    this.timeRemaining--;
                } else {
                    clearInterval(this.timeInterval);
                }
            }, 1000);
        },
        nextStep() {
            this.getAccountDetails();
        },
        phoneUpdated(userData) {
            this.tooManyRequests = false;
            this.notificationType = OMNI;
            this.user = userData;
            this.setTimeRemaining();
        },
        getPhone() {
            const formattedPhoneNumber = parsePhoneNumber(this.user.phone, this.user.country);

            return formattedPhoneNumber.formatInternational()
        },
        async handleSubmit() {
            this.v$.$touch();

            if (this.v$.$invalid) {
                return;
            }

            if (!recaptchaUtils.isAvailable()) {
                this.validateCode('');
                return;
            }

            this.$refs.codeRecaptcha.reset();
            this.$refs.codeRecaptcha.execute();
        },
        async sendCodeRecaptcha(notification_type) {
            this.temporalNotificationType = notification_type;

            if (!recaptchaUtils.isAvailable()) {
                this.sendCode('');
                return;
            }

            this.$refs.recaptchaComponent.reset();
            this.$refs.recaptchaComponent.execute();
        },
        async sendCode(token) {
            try {
                this.sendingCode = true;

                const payload = {
                    type: this.temporalNotificationType || this.notificationType,
                    action: 'phone_verification',
                    message: '',
                    from: 'Tendencys',
                    token,
                };

                await this.axiosAccount.post('/api/notifications', payload);
                this.setTimeRemaining();

                if (this.temporalNotificationType) {
                    this.notificationType = this.temporalNotificationType;
                    this.temporalNotificationType = null;
                }
            } catch(error) {
                if (error.response.status === 429) {
                    this.tooManyRequests = true;
                    return;
                }

                if (error.response.status === 404) {
                    this.redirectToLogin();
                    return;
                }

                this.showError(error);
            } finally {
                this.sendingCode = false;
            }
        },
        async getAccountDetails() {
            try {
                this.loadingAccount = true;

                const { data } = await this.axiosAccount.get('/api/accounts/me');

                this.user = data;
            } catch(error) {
                if (error.response.status === 422) {
                    this.$refs.registerPhoneModal.show();
                    return;
                }
                
                if (error.response.status === 404) {
                    this.redirectToLogin();  
                    return;
                }

                this.showError(error);
            } finally {
                this.loadingAccount = false;
            }
        },
        async validateCode(token) {
            try {
                this.validatingCode = true;
                
                const config = { auth: { username: this.user.email, password: '' } };
                const payload = { code: this.formData.verificationCode, token };
                const { data } = await this.axiosAccount.post('/api/notifications/code-verification', payload, config)
                const { redirect_url } = data;

                if (redirect_url) {
                    redirect_url.startsWith('/')
                        ? this.$router.push(redirect_url)
                        : window.location.replace(redirect_url);
                } else {
                    this.$router.push({ name: 'dashboard' });
                }
            } catch (error) {
                this.showError(error);
            } finally {
                this.validatingCode = false
            }
        },
        getMessageByType(type, phone) {
            let options = {
                sms: 'We have sent a verification code in an SMS message to the number',
                whatsapp: 'We have sent a verification code in a WhatsApp message to the number',
                omni: 'We have sent a verification code to the number'
            };
            return `${this.$t(options[type] || 'We have sent a verification code to the number')} <strong>${phone}</strong>`;
        }
    },
};
</script>

<style lang="scss">
.phone-verification-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
        font-size: 2.25rem;
        font-weight: 700;
        margin-bottom: 20px;
    }

    &__info-text {
        color: #555555;
        font-size: 1rem;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 25px;
        width: 500px;
        max-width: 100%;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 24px;
        max-width: 100%;
        width: 450px;
    }

    &__wait-message {
        color: $general-black;
        font-size: 0.9rem;
        font-weight: 400;
        margin-bottom: 15px;
        max-width: 400px;
        background-color: $general-white;
        border-radius: 8px;
        padding: 10px;

        strong {
            font-weight: 800;
        }
    }

    &__update-phone {
        color: #9093A1;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 24px;
    }

    &__link {
        color: $accounts-secondary !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        text-decoration: none;
    }

    &__other-methods {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
        margin-bottom: 32px;
        width: 100%;
    }

    &__other-method-button {
        align-items: center !important;
        display: flex !important;
        gap: 4px;
        height: 46px;
        padding-left: 8px !important;
        padding-right: 8px !important;
        margin-bottom: 0 !important;
        max-width: 100%;
        width: 100%;

        @media(min-width: 768px) {
            width: auto;
        }
    }

    &__other-method-button-text {
        line-height: 130%;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
        white-space: nowrap;
    }

    &__other-method-arrow {
        font-size: 14px;
        margin-left: auto;
        margin-top: -2px;
    }

    &__other-method-icon {
        margin-top: -2px;
    }
}
</style>
