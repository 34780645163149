<template>
    <div class="phone-verification-page">
        <div class="phone-verification-page__content">
            <logos-container-ui center />
            <phone-verification-form />
            <p class="phone-verification-page__support-message">
                {{ $t('If you are still having problems with login, start a support chat') }}.
            </p>
        </div>
    </div>
</template>

<script>
import PhoneVerificationForm from '@/components/forms/PhoneVerification.vue';
import LogosContainerUi from '@/components/ui/LogosContainer.vue'

export default {
    name: 'phone-verification-page',
    components: {
        PhoneVerificationForm,
        LogosContainerUi
    },
};
</script>

<style lang="scss">
.phone-verification-page {
    text-align: center;
    position: relative;

    &__back {
        position: absolute;
        left: 0;
        top: 0;
    }

    &__content {
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;
    }

    &__logo {
        margin-bottom: 35px;
    }

    &__support-message {
        color: #c4c4c4;
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 0;
    }

    &__img {
        max-width: 120px;
        width: 100%;
    }
}
</style>
