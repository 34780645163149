<template>
    <div class="phone-verification-skeleton">
        <b-skeleton class="phone-verification-skeleton__title" />
        <div class="d-flex flex-column justify-content-center align-items-center mb-4">
            <b-skeleton class="phone-verification-skeleton__text" />
            <b-skeleton class="phone-verification-skeleton__text" />
        </div>
        <div class="w-100 d-flex justify-content-center align-items-center flex-column mb-3">
            <b-skeleton class="phone-verification-skeleton__input mb-2" type="input" />
            <b-skeleton class="phone-verification-skeleton__input mb-2" type="input" />
            <b-skeleton class="phone-verification-skeleton__input mb-2" type="input" />
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center mb-3">
            <b-skeleton class="phone-verification-skeleton__text-bottom" />
        </div>
        <div class="w-100 d-flex justify-content-center align-items-center mb-3">
            <b-skeleton class="phone-verification-skeleton__method-button mx-2" type="input" width="200px" />
            <b-skeleton class="phone-verification-skeleton__method-button mx-2" type="input" width="200px" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'PhoneVerificationSkeleton',
};
</script>

<style lang="scss">
    .phone-verification-skeleton {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        text-align: center;
        width: 525px;

        &__title {
            height: 40px !important;
            margin-bottom: 20px !important;
            margin-left: auto;
            margin-right: auto;
            max-width: 70%;
        }

        &__text {
            max-width: 500px;
            width: 100%;
        }

        &__input {
            max-width: 450px;
            width: 100%;
        }

        &__text-bottom {
            max-width: 243px;
            width: 100%;
        }

        &__method-button {
            max-width: 243px;
            width: 100%;
        }
    }
</style>
