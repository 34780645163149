<template>
    <ui-modal
        ref="confirmationModal"
        class="pending-account-information-modal"
        centered
        :title="$t('Update information')"
        @hidden="resetModal"
    >
        <div>
            <p>{{ $t('Enter the information requested below') }}:</p>
            <div>
                <ui-select
                    v-if="!user.country"
                    id="country"
                    v-model="formData.country"
                    name="country"
                    option-label="name"
                    option-value="code"
                    :label="$t('Country')"
                    :placeholder="$t('Choose an option')"
                    :options="countryList"
                    :field="v$.formData.country"
                />
                <ui-input
                    v-if="!user.phone"
                    id="phone"
                    v-model="formData.phone"
                    name="phone"
                    type="number"
                    placeholder="55 1254 5678"
                    :label="$t('Phone')"
                    :field="v$.formData.phone"
                >
                    <template v-if="phoneCode" #prepend>
                        +{{ phoneCode }}
                    </template>
                </ui-input>
            </div>
            <vue-recaptcha
                class="w-100"
                ref="recaptcha"
                size="invisible"
                @verify="updateAccount"
                :sitekey="CONST.CAPTCHACODE"
                :loadRecaptchaScript="true"
            />
        </div>
        <template #footer>
            <ui-button variant="secondary" :disabled="updatingAccount" @click="$refs.confirmationModal.close()">
                {{ $t('Cancel') }}
            </ui-button>
            <ui-button :loading="updatingAccount" @click="handleSubmit">
                {{ $t('Save') }}
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, maxLength, minLength, required } from '@vuelidate/validators';
import { getCountryCallingCode } from 'libphonenumber-js';
import VueRecaptcha from 'vue-recaptcha';

import UiButton from '@/components/ui/buttons/Button.vue';
import UiInput from '@/components/ui/inputs/Input.vue';
import UiSelect from '@/components/ui/inputs/Select.vue';
import UiModal from '@/components/ui/modal/Modal.vue';

import recaptchaUtils from '@/utils/recaptcha.utils';

export default {
    name: 'PendingAccountInformationModal',
    components: {
        UiSelect,
        UiButton,
        UiInput,
        UiModal,
        VueRecaptcha,
    },
    props: {
        user: {
            type: Object,
            default: null,
            required: true,
        },
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        const form_data = {};

        if (!this.user.phone) {
            form_data.phone = '';
        }

        if (!this.user.country) {
            form_data.country = '';
        }

        return {
            countryList: [],
            formData: { ...form_data },
            initialFormData: { ...form_data },
            updatingAccount: false,
        };
    },
    computed: {
        phoneCode() {
            const country = this.user?.country || this.formData?.country;

            if (country) {
                return getCountryCallingCode(country);
            }

            return null
        },
        rules() {
            const rules = { formData: {} };

            if (!this.user.country) {
                rules.formData.country = {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('country') }), required),
                    $autoDirty: true,
                };
            }

            if (!this.user.phone) {
                rules.formData.phone = {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('phone') }), required),
                    minLength: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params ), minLength(8)),
                    maxLength: helpers.withMessage(({ $params }) => this.$t('This field must have a maximum of {max} characters', $params ), maxLength(12)),
                    $autoDirty: true,
                };
            }

            return rules;
        },
    },
    validations() {
        return this.rules;
    },
    async mounted() {
        if (!this.user.country) {
            this.countryList = await this.fnApiGetCountries();
        }
    },
    methods: {
        close() {
            this.$refs.confirmationModal.close();
        },
        show() {
            this.$refs.confirmationModal.show();
        },
        resetModal() {
            this.formData = this.initialFormData;
        },
        async handleSubmit() {
            this.v$.$touch();

            if (this.v$.$invalid) {
                return;
            }

            if (!recaptchaUtils.isAvailable()) {
                this.updateAccount('');
                return;
            }

            this.$refs.recaptcha.reset();
            this.$refs.recaptcha.execute();
        },
        async updateAccount(token) {
            try {
                this.updatingAccount = true;

                const { data } = await this.axiosAccount.put(`/api/accounts/me`, { ...this.formData, token });

                this.$emit('accountInformationUpdated', data);
                this.resetModal();
                this.close();
            } catch(error) {
                this.showError(error);
            } finally {
                this.updatingAccount = false;
            }
        },
    },
};
</script>

<style lang="scss">
.pending-account-information-modal {
    &__link {
        color: #004C74 !important;
        font-weight: 600 !important;
    }
}
</style>
