<template>
    <ui-modal
        ref="confirmationModal"
        class="phone-verification-modal"
        centered
        :title="updating ? $t('Update phone') : $t('Register phone')"
        @hidden="resetModal"
    >
        <div>
            <p>{{ $t('Enter the information requested below') }}:</p>
            <div>
                <ui-input
                    id="phone"
                    name="phone"
                    type="number"
                    placeholder="55 1254 5678"
                    v-model="formData.phone"
                    :label="$t('Phone')"
                    :field="v$.formData.phone"
                >
                    <template v-if="phoneCode" #prepend>
                        +{{ phoneCode }}
                    </template>
                </ui-input>
            </div>
            <vue-recaptcha
                class="w-100"
                ref="recaptcha"
                size="invisible"
                @verify="updatePhone"
                :sitekey="CONST.CAPTCHACODE"
                :loadRecaptchaScript="true"
            />
        </div>
        <template #footer>
            <ui-button variant="secondary" :disabled="savingPhone" @click="$refs.confirmationModal.close()">
                {{ $t('Cancel') }}
            </ui-button>
            <ui-button :loading="savingPhone" @click="handleSubmit">
                {{ $t('Save') }}
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { helpers, maxLength, minLength, required } from '@vuelidate/validators';
import { getCountryCallingCode } from 'libphonenumber-js'
import VueRecaptcha from 'vue-recaptcha';

import UiButton from '@/components/ui/buttons/Button.vue';
import UiInput from '@/components/ui/inputs/Input.vue';
import UiModal from '@/components/ui/modal/Modal.vue';

import recaptchaUtils from '@/utils/recaptcha.utils';

export default {
    name: 'PhoneNumberModal',
    components: {
        UiButton,
        UiInput,
        UiModal,
        VueRecaptcha,
    },
    props: {
        country: {
            type: String,
            default: null,
            required: true,
        },
        updating: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            formData: {
                phone: '',
            },
            savingPhone: false,
        };
    },
    computed: {
        phoneCode() {
            return getCountryCallingCode(this.country);
        }
    },
    validations() {
        return {
            formData: {
                phone: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('phone') }), required),
                    minLength: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params ), minLength(8)),
                    maxLength: helpers.withMessage(({ $params }) => this.$t('This field must have a maximum of {max} characters', $params ), maxLength(12)),
                    $autoDirty: true,
                },
            },
        };
    },
    methods: {
        close() {
            this.$refs.confirmationModal.close();
        },
        show() {
            this.$refs.confirmationModal.show();
        },
        resetModal() {
            this.formData = { phone: '' };
            this.v$.$reset();
        },
        async handleSubmit() {
            this.v$.$touch();

            if (this.v$.$invalid) {
                return;
            }

            if (!recaptchaUtils.isAvailable()) {
                this.updatePhone('');
                return;
            }

            this.$refs.recaptcha.reset();
            this.$refs.recaptcha.execute();
        },
        async updatePhone(token) {
            try {
                this.savingPhone = true;

                const { data } = await this.axiosAccount.put('/api/accounts/me', { phone: this.formData.phone, token });

                this.$emit('phoneUpdated', data);
                this.resetModal();
                this.close();
            } catch(error) {
                this.showError(error);
            } finally {
                this.savingPhone = false;
            }
        },
    },
};
</script>

<style lang="scss">
.phone-verification-modal {
    &__link {
        color: #004C74 !important;
        font-weight: 600 !important;
    }
}
</style>
