const CALL = 'call';
const OMNI = 'omni';
const SMS = 'sms';
const WHATSAPP = 'whatsapp';

export {
    CALL,
    OMNI,
    SMS,
    WHATSAPP,
};
